<template>
  <div id="app" class="principal justify-content-center">
    <div id="block_container" class="d-flex justify-content-center contenedor">
      <div class="contenedor" v-show="$mq != 'mobile'">
        <div class="card text-center  ">
          <div class="card-body text-secondary">
            <img
              class="card-img-top"
              src="../images/login.png"
              alt="Card image cap"
            />
        
            <form name="form" @submit.prevent="handleRegistro">
              <p class="texto">{{$t('register')}}</p>
              <b-row class="mb-5">
              <b-col style="text-align: end;">
                 <a href="#" @click.prevent="changeLanguage('en')"> <img
                      src="../images/icons8-english-96.png" style="width: 40px;"
                  /></a>
              </b-col>
              <b-col style="text-align: start;">
                 <a href="#" @click.prevent="changeLanguage('es')">
                  <img
                      src="../images/icons8-spain-96.png" style="width: 40px;"
                  /></a>
              </b-col>
            </b-row>
              <div class="container">
                
                <div v-if="tipoUsuario === 'usuario'" class="row mb-1">
                  <b-col cols="3">
                    <label for="tipoUsuario" class="form-label"
                          >{{$t('userType')}}</label
                        >
                    <b-form-select
                      v-model="tipoUsuario"
                      class="form-control"
                      :options="tiposUsuario"
                    ></b-form-select>
                  </b-col>
                  <div class="col-sm">
                    <label for="codigoKit" class="form-label"
                      >{{$t('kit')}}</label
                    >
                    <b-form-group>
                      <b-form-input
                        class="form-control"
                        v-model="codigoKit"
                        id="codigoKit"
                        rows="1"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        Campo obligatorio
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <b-col cols="3" v-if="tipoUsuario === 'profesional'">
                    <label for="tipoUsuario" class="form-label"
                          >{{$t('userType')}}</label
                        >
                    <b-form-select
                      v-model="tipoUsuario"
                      class="form-control"
                      :options="tiposUsuario"
                    ></b-form-select>
                  </b-col>
                  <b-col cols="4">
                    <label for="nombre" class="form-label">{{$t('firstname')}}</label>
                    <b-form-input
                      v-validate="'required'"
                      class="form-control"
                      v-model="nombre"
                      id="nombre"
                      rows="1"
                    ></b-form-input>
                  </b-col>

                  <div class="col-sm">
                    <label for="apellidos" class="form-label">{{$t('surname')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="apellidos"
                      id="apellidos"
                      rows="1"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row mt-3" >
                  <b-col cols="7" v-if="tipoUsuario === 'usuario'">
                    <b-row>
                      <label for="dia" class="form-label"
                        >{{$t('birth')}}</label
                      >
                    </b-row>
                    <b-row>
                      <b-col cols="3">
                        <b-form-input
                          class="form-control"
                          v-model="dia"
                          id="dia"
                          rows="1"
                          :placeholder="$t('day')"
                          @change="compruebaMenor"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="5">
                        <b-form-select
                          v-model="mes"
                          class="form-control"
                          :options="meses"
                          :placeholder="$t('month')"
                          @change="compruebaMenor"
                        ></b-form-select>
                      </b-col>

                      <b-col cols="4">
                        <b-form-input
                          class="form-control"
                          v-model="anyo"
                          id="anyo"
                          rows="1"
                          :placeholder="$t('year')"
                          @change="compruebaMenor"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="7" v-if="tipoUsuario === 'profesional'">
                    <label for="razonSocial" class="form-label">{{$t('razon')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="razonSocial"
                      id="razonSocial"
                      rows="1"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="5">
                    <label for="sexo" class="ml-5 form-label">{{$t('sex')}}</label>
                    <b-row>
                      <b-form-radio-group class="pt-2">
                        <b-form-radio
                          v-model="sexo"
                          name="some-radios"
                          value="h"
                          >{{$t('male')}}</b-form-radio
                        >

                        <b-form-radio
                          v-model="sexo"
                          name="some-radios"
                          value="m"
                          >{{$t('female')}}
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-row>
                  </b-col>
                </div>
                <div class="row mt-2" v-if="tipoUsuario === 'profesional'">
                  <b-col>
                    <label for="entidadBancaria" class="form-label">{{$t('entidadBancaria')}}</label>
                    <b-form-input
                      v-validate="'required'"
                      class="form-control"
                      v-model="entidadBancaria"
                      id="entidadBancaria"
                      rows="1"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                  <label for="iban" class="form-label">{{$t('iban')}}</label>
                    <b-form-input
                      v-validate="'required'"
                      class="form-control"
                      v-model="iban"
                      id="iban"
                      rows="1"
                    ></b-form-input>
                  </b-col>
                </div>
                
                <div v-if="menor && tipoUsuario === 'usuario'" class="row mt-4">
                  <div class="col-sm">
                    <label for="dniAdulto" class="form-label">{{$t('adultdni')}}</label>
                    <b-form-file
                      id="dniAdulto"
                      class="mb-4"
                      v-model="dniAdulto"
                      placeholder=""
                      drop-placeholder=""
                      browse-text="Examinar"
                    ></b-form-file>
                  </div>

                  <div class="col-sm">
                    <label for="dniMenor" class="form-label">{{$t('youngerdni')}}</label>
                    <b-form-file
                      id="dniMenor"
                      class="mb-4"
                      v-model="dniMenor"
                      placeholder=""
                      drop-placeholder=""
                      browse-text="Examinar"
                    ></b-form-file>
                  </div>
                </div>

                <div class="row  mt-3">
                  <div class="col-sm">
                    <label for="name" class="form-label">{{$t('telephone')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="telefono"
                      id="telefono"
                      rows="1"
                    ></b-form-input>
                  </div>
                  <div class="col-sm">
                    <label for="email" class="form-label">E-mail</label>
                    <b-form-input
                      class="form-control"
                      v-model="email"
                      id="email"
                      rows="1"
                    ></b-form-input>
                  </div>
                  <div class="col-sm" v-if="tipoUsuario === 'profesional'" >
                    <label for="cif" class="form-label">CIF</label>
                    <b-form-input
                      class="form-control"
                      v-model="cif"
                      id="cif"
                      rows="1"
                    ></b-form-input>
                  </div>
                </div>

                <div class="row mb-3"></div>
                <div class="row mb-3">
                  <b-col cols="5">
                    <label for="direccion" class="form-label">{{$t('address')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="direccion"
                      id="direccion"
                      rows="1"
                    ></b-form-input>
                  </b-col>

                  <b-col>
                    <label for="provincia" class="form-label">{{$t('province')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="provincia"
                      id="provincia"
                      rows="1"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <label for="poblacion" class="form-label">{{$t('town')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="poblacion"
                      id="poblacion"
                      rows="1"
                    ></b-form-input>
                  </b-col>
                  <div class="col-sm">
                    <label for="codigoPostal" class="form-label"
                      >{{$t('postal')}}</label
                    >
                    <b-form-input
                      class="form-control"
                      v-model="codigoPostal"
                      id="codigoPostal"
                      rows="1"
                    ></b-form-input>
                  </div>
                  <div class="col-sm">
                    <label for="pais" class="form-label">{{$t('country')}}</label>
                    <b-form-input
                      class="form-control"
                      v-model="pais"
                      id="pais"
                      rows="1"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm">
                    <label for="password" class="form-label">{{$t('password')}}</label>
                    <input
                      v-validate="'required|min:6'"
                      name="password"
                      type="password"
                      class="form-control"
                      ref="password"
                      v-model="password"
                      style="background-color: #efefef;"
                    />
                  </div>

                  <div class="col-sm">
                    <label for="password2" class="form-label"
                      >{{$t('confirm')}}</label
                    >
                    <input
                      v-validate="'required|confirmed:password'"
                      name="password_confirmation"
                      type="password"
                      class="form-control"
                      data-vv-as="password"
                      v-model="password2"
                      style="background-color: #efefef;"
                    />
                  </div>
                </div>

                <div class="alert alert-danger" v-show="errors.any()">
                  <div v-if="errors.has('password')">
                    {{$t('passwordminimum')}}
                  </div>
                  <div v-if="errors.has('password_confirmation')">
                    {{$t('passwordmatch')}}
                  </div>
                </div>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label mt-5"
                  id="checkbox-3"
                  v-model="uso"
                  name="checkbox-3"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('accepterms')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label "
                  id="checkbox-1"
                  v-model="cinformado"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('acceptconsent')}}<a href="https://mendelsbrain.com/consentimiento-informado/"> {{$t('informedconsent')}}</a>
                </b-form-checkbox>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label"
                  id="checkbox-2"
                  v-model="novedades"
                  name="checkbox-2"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('acceptupdates')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label mb-4"
                  id="checkbox-4"
                  v-model="avance"
                  name="checkbox-4"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('acceptcontribute')}}
                </b-form-checkbox>
                
                 <!--<b-form-checkbox
                  v-if="tipoUsuario === 'usuario'"
                  class="form-label mb-4"
                  id="checkbox-5"
                  v-model="checkprofessional"
                  name="checkbox-5"
                  value="true"
                  unchecked-value="false"
                >
               <b-row>
                  <b-col style="padding:0px -300px 0px 20px; margin:0px -170px 0px 0px;width:200px">{{$t('acceptprofessional1')}}</b-col>
                  <b-col><v-select style="padding:0px 0px 0px 0px; margin:0px 0px 0px 0px;width:300px;" v-model="professional" label="nombre" :options="profesionales" /></b-col>
                  <b-col style="padding:0px 0px 0px 0px; margin:0px 0px 0px 0px;width:300px">{{$t('acceptprofessional2')}}</b-col>
                </b-row>
                </b-form-checkbox>-->
              
              </div>
              <div class="form-group">
                <button variant="outline-success" class="btn-registro">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm "
                  ></span>
                  <span>{{$t('register')}}</span>
                </button>
              </div>
              <router-link to="/login" class="nav-link"
                ><font-awesome-icon icon="sign-in-alt" /> {{$t('backlogin')}}</router-link
              >
            </form>
          </div>

          <b-modal id="bv-modal-mensajes" hide-footer>
            <template #modal-title>
              <p class="titulo-mensajes">{{ titulo_msgbox }}</p>
            </template>
            <div class="d-block text-center">
              <li
                v-for="item in textoMensajes"
                :key="item.mensaje"
                class="texto-mensajes"
              >
                {{ item.mensaje }}
              </li>
            </div>
            <div class="d-block text-center">
              <b-button
                class="mt-3 btn-mensajes text-center"
                @click="accionCerrarMensajes"
                >{{$t('close')}}</b-button
              >
            </div>
          </b-modal>
        </div>
      </div>
<!---------------------------------------------------- FORMULARIO EXCLUSIVO PARA EL MÓVIL ------------------->
      <div class="contenedor" v-show="$mq === 'mobile'">
        <div class="card text-center  ">
          <div class="card-body text-secondary">
            <img
              class="card-img-top"
              src="../images/login.png"
              alt="Card image cap"
            />
            <form name="form" @submit.prevent="handleRegistro">
              <p class="texto">Registrar</p>
              <div class="container">
                    <label for="tipoUsuario" class="form-label"
                          >{{$t('userType')}}</label
                        >
                    <b-form-select
                      v-model="tipoUsuario"
                      class="form-control"
                      :options="tiposUsuario"
                    ></b-form-select>
                <label for="codigoKit" v-if="tipoUsuario === 'usuario'" class="form-label">{{$t('kit')}}</label>
                <b-form-input v-if="tipoUsuario === 'usuario'"
                  class="form-control"
                  v-model="codigoKit"
                  id="codigoKit"
                  rows="1"
                ></b-form-input>
                <label for="nombre" class="form-label">{{$t('firstname')}}</label>
                <b-form-input
                  v-validate="'required'"
                  class="form-control"
                  v-model="nombre"
                  id="nombre"
                  rows="1"
                ></b-form-input>

                <label for="apellidos" class="form-label">{{$t('surname')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="apellidos"
                  id="apellidos"
                  rows="1"
                ></b-form-input>

                <b-row>
                  <label v-if="tipoUsuario === 'usuario'" for="dia" class="form-label"
                    >{{$t('birth')}}</label
                  >
                </b-row>
                <b-row v-if="tipoUsuario === 'usuario'">
                  <b-col cols="4">
                    <b-form-input
                      class="form-control"
                      v-model="dia"
                      id="dia"
                      rows="1"
                      :placeholder="$t('day')"
                      @change="compruebaMenor"
                    ></b-form-input>
                  </b-col>
                  <b-col style="margin:0px -25px 0px -25px">
                    <b-form-select
                      v-model="mes"
                      class="form-control"
                      :options="meses"
                      :placeholder="$t('month')"
                      @change="compruebaMenor"
                    ></b-form-select>
                  </b-col>
                  <b-col cols="4" style="width:120px">
                    <b-form-input
                      class="form-control"
                      v-model="anyo"
                      id="anyo"
                      rows="1"
                      :placeholder="$t('year')"
                      @change="compruebaMenor"
                    ></b-form-input>
                  </b-col>
                </b-row>
      
                    <label v-if="tipoUsuario === 'profesional'" for="razonSocial" class="form-label">{{$t('razon')}}</label>
                    <b-form-input v-if="tipoUsuario === 'profesional'"
                      class="form-control"
                      v-model="razonSocial"
                      id="razonSocial"
                      rows="1"
                    ></b-form-input>
                    <label v-if="tipoUsuario === 'profesional'" for="entidadBancaria" class="form-label">{{$t('entidadBancaria')}}</label>
                    <b-form-input v-if="tipoUsuario === 'profesional'"
                      v-validate="'required'"
                      class="form-control"
                      v-model="entidadBancaria"
                      id="entidadBancaria"
                      rows="1"
                    ></b-form-input>
               
                  <label v-if="tipoUsuario === 'profesional'" for="iban" class="form-label">{{$t('iban')}}</label>
                    <b-form-input v-if="tipoUsuario === 'profesional'"
                      v-validate="'required'"
                      class="form-control"
                      v-model="iban"
                      id="iban"
                      rows="1"
                    ></b-form-input>
                
                <label for="sexo" class=" mt-3 form-label">{{$t('sex')}}</label>
                <b-row>
                  <b-form-radio-group>
                    <b-form-radio v-model="sexo" name="some-radios" value="h"
                      >{{$t('male')}}</b-form-radio
                    >

                    <b-form-radio v-model="sexo" name="some-radios" value="m"
                      >{{$t('female')}}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-row>

                <div v-if="menor" class="row mt-4">
                  <div class="col-sm">
                    <label for="dniAdulto" class="form-label">{{$t('adultdni')}}</label>
                    <b-form-file
                      id="dniAdulto"
                      class="mb-4"
                      v-model="dniAdulto"
                      placeholder=""
                      drop-placeholder=""
                      browse-text="Examinar"
                    ></b-form-file>
                  </div>

                  <div class="col-sm">
                    <label for="dniMenor" class="form-label">{{$t('youngerdni')}}</label>
                    <b-form-file
                      id="dniMenor"
                      class="mb-4"
                      v-model="dniMenor"
                      placeholder=""
                      drop-placeholder=""
                      browse-text="Examinar"
                    ></b-form-file>
                  </div>
                </div>

                <label for="name" class="form-label">{{$t('telephone')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="telefono"
                  id="telefono"
                  rows="1"
                ></b-form-input>

                <label for="email" class="form-label">E-mail</label>
                <b-form-input
                  class="form-control"
                  v-model="email"
                  id="email"
                  rows="1"
                ></b-form-input>
                    <label  v-if="tipoUsuario === 'profesional'" for="cif" class="form-label">CIF</label>
                    <b-form-input  v-if="tipoUsuario === 'profesional'"
                      class="form-control"
                      v-model="cif"
                      id="cif"
                      rows="1"
                    ></b-form-input>
         
                <label for="direccion" class="form-label">{{$t('address')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="direccion"
                  id="direccion"
                  rows="1"
                ></b-form-input>

                <label for="provincia" class="form-label">{{$t('province')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="provincia"
                  id="provincia"
                  rows="1"
                ></b-form-input>

                <label for="poblacion" class="form-label">{{$t('town')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="poblacion"
                  id="poblacion"
                  rows="1"
                ></b-form-input>

                <label for="codigoPostal" class="form-label">{{$t('postal')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="codigoPostal"
                  id="codigoPostal"
                  rows="1"
                ></b-form-input>

                <label for="pais" class="form-label">{{$t('country')}}</label>
                <b-form-input
                  class="form-control"
                  v-model="pais"
                  id="pais"
                  rows="1"
                ></b-form-input>

                <label for="password" class="mt-5 form-label">{{$t('password')}}</label>
                <input
                  v-validate="'required|min:6'"
                  name="password"
                  type="password"
                  class="form-control"
                  ref="password"
                  v-model="password"
                  style="background-color: #efefef;"
                />

                <label for="password2" class="form-label"
                  >{{$t('confirm')}}</label
                >
                <input
                  v-validate="'required|confirmed:password'"
                  name="password_confirmation"
                  type="password"
                  class="form-control mb-3"
                  data-vv-as="password"
                  v-model="password2"
                  style="background-color: #efefef;"
                />

                <div class="alert alert-danger" v-show="errors.any()">
                  <div v-if="errors.has('password')">
                    {{$t('passwordminimun')}}
                  </div>
                  <div v-if="errors.has('password_confirmation')">
                    {{$t('passwordmatch')}}
                  </div>
                </div>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label mt-5"
                  id="checkbox-3"
                  v-model="uso"
                  name="checkbox-3"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('accepterms')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label "
                  id="checkbox-1"
                  v-model="cinformado"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('acceptconsent')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label"
                  id="checkbox-2"
                  v-model="novedades"
                  name="checkbox-2"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('acceptupdates')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="tipoUsuario === 'usuario'"
                  class="form-label mb-5"
                  id="checkbox-4"
                  v-model="avance"
                  name="checkbox-4"
                  value="true"
                  unchecked-value="false"
                >
                {{$t('acceptcontribute')}}
                </b-form-checkbox>
                <!--<b-form-checkbox
                  v-if="tipoUsuario === 'usuario'"
                  class="form-label mb-5 mt-3 mr-0  pr-0"
                  id="checkbox-5"
                  v-model="checkprofessional"
                  name="checkbox-5"
                  value="true"
                  unchecked-value="false"
                >
                    {{$t('acceptprofessional1')}}
                    <v-select v-model="professional" label="nombre" :options="profesionales" />
                    {{$t('acceptprofessional2')}}
                  
                </b-form-checkbox>-->
              </div>
              <div class="form-group">
                <button variant="outline-success" class="btn-registro">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm "
                  ></span>
                  <span>{{$t('register')}}</span>
                </button>
              </div>
              <router-link to="/login" class="nav-link"
                ><font-awesome-icon icon="sign-in-alt" /> {{$t('backlogin')}}</router-link
              >
            </form>
          </div>

          <b-modal id="bv-modal-mensajes" hide-footer>
            <template #modal-title>
              <p class="titulo-mensajes">{{ titulo_msgbox }}</p>
            </template>
            <div class="d-block text-center">
              <li
                v-for="item in textoMensajes"
                :key="item.mensaje"
                class="texto-mensajes"
              >
                {{ item.mensaje }}
              </li>
            </div>
            <div class="d-block text-center">
              <b-button
                class="mt-3 btn-mensajes text-center"
                @click="accionCerrarMensajes"
                >{{$t('close')}}</b-button
              >
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
//import vSelect from "vue-select";

export default {
  name: 'Registro',
  mixins: [validationMixin],
  data() {
    return {
      // Campos perfil usuario
      codigoKit: '',
      nombre: '',
      apellidos: '',
      sexo: '',
      fechaNacimiento: '',
      email: '',
      telefono: '',
      direccion: '',
      numero: '',
      provincia: '',
      poblacion: '',
      codigoPostal: '',
      pais: '',
      password: '',
      password2: '',
      dniAdulto: null,
      dniMenor: null,
      dniAdultoId: '',
      dniMenorId: '',
      uso: false,
      cinformado: false,
      novedades: false,
      avance: false,
      menor: false,
      decada: true,
      checkprofessional:false,
      professional:null,
      cif:'',
      razonSocial:'',
      entidadBancaria:'',
      iban:'',
      // Auxiliar
      loading: false,
      role: ['ROLE_USER'],
      textoMensajes: [],
      titulo_msgbox: '',
      validado: 0,
      anyo: '',
      mes: null,
      dia: '',
      meses: [
        { value: null, text: 'Mes' },
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' }
      ],
      tiposUsuario: [
        { value: 'usuario', text: 'Usuario' },
        { value: 'profesional', text: 'Profesional' }
      ],
      tipoUsuario: 'usuario',
      profesionales:[]
    };
  },
  validations: {
    form: {
      codigoKit: {
        required
      }
    }
  },
  methods: {
    validateState(codigoKit){
      const { $dirty, $error } = this.$v.form[codigoKit];
      return $dirty ? !$error : null;
    },
    changeLanguage(lang)
    {
      this.$parent.changeLanguage(lang);
    },
    handleRegistro() {
      this.validarFormulario();
      if (this.validado == 1) {
        if (this.menor) {
          this.submitDniAdulto();
        } else {
          this.enviarUsuario();
        }
      } else {
        this.titulo_msgbox = 'Debe rellenar estos campos';
        this.$bvModal.show('bv-modal-mensajes');
      }
    },
    submitDniAdulto() {
      let formData = new FormData();
      formData.append('dniimagen', this.dniAdulto);
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/perfilusuario/dni',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(response => {
          this.dniAdultoId = response.data;
          this.submitDniMenor();
        })
        .catch(function() {
          //console.log'FAILURE!!');
        });
    },
    submitDniMenor() {
      let formData = new FormData();
      formData.append('dniimagen', this.dniMenor);
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/perfilusuario/dni',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(response => {
          this.dniMenorId = response.data;
          this.enviarUsuario();
        })
        .catch(function() {
          //console.log'FAILURE!!');
        });
    },
    compruebaMenor() {
      if (this.dia && this.mes && this.anyo) {
        let dia2dig = this.dia;
        if (this.dia.length == 1) {
          dia2dig = '0' + dia2dig;
        }
        this.fechaNacimiento = this.anyo + '-' + this.mes + '-' + dia2dig;
        var date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        if (new Date(this.fechaNacimiento).getTime() > date.getTime()) {
          this.menor = true;
        } else {
          this.menor = false;
        }

        //console.lognew Date(this.fechaNacimiento).getTime());
        //console.logdate.getTime());
        
        //console.lognew Date(this.fechaNacimiento));
        //console.logdate);

        //console.logthis.menor);
      }
    },
    enviarUsuario() {
      axios
        .post('https://api.mendelbrain.com:55010/api/mendel/auth/signup', {
          username: this.email,
          email: this.email,
          password: this.password,
          role: this.role,
          attributes:[]
        })
        .then(response => {
          this.enviarPerfilUsuario(response.data.id);
        })
        .catch(error => {
          console.log(error);
          this.errored = true;
        });
    },
    enviarPerfilUsuario(idUsuario) {
      axios
        .post('https://api.mendelbrain.com:55020/api/mendels/perfilusuario', {
          idUsuario: idUsuario,
          kit: this.codigoKit,
          nombre: this.nombre,
          apellidos: this.apellidos,
          sexo: this.sexo,
          fechaNacimiento: this.fechaNacimiento,
          email: this.email,
          telefono: this.telefono,
          direccion: this.direccion,
          provincia: this.provincia,
          poblacion: this.poblacion,
          codigoPostal: this.codigoPostal,
          pais: this.pais,
          uso: this.uso,
          cinformado: this.cinformado,
          novedades: this.novedades,
          avance: this.avance,
          dniAdulto: this.dniAdultoId,
          dniMenor: this.dniMenorId,
          fechaRegistro: new Date(),
          profesional: this.professional,
          checkprofessional: this.checkprofessional,
          tipoPerfil:this.tipoUsuario,
          cif: this.cif,
          razonSocial: this.razonSocial,
          entidadBancaria: this.entidadBancaria,
          iban: this.iban
        })
        .then(
          this.textoMensajes.push({
            mensaje:
              'Usuario registrado correctamente. Podrá acceder con su nombre de usuario ' +
              this.email +
              ' y la contraseña proporcionada.'
          }),
          (this.titulo_msgbox = 'Usuario registrado correctamente'),
          this.$bvModal.show('bv-modal-mensajes')
        )
        .catch(error => {
          console.log(error);
          this.errored = true;
        });
    },
    validarFormulario() {
      this.textoMensajes = [];
      this.validado = 1;
      // Comprobación de campos
      if ((this.dia > 31 || this.dia < 1 || this.anyo < 1900 || !this.mes) && this.tipoUsuario == 'usuario') {
        this.textoMensajes.push({ mensaje: 'Fecha' });
        this.validado = 0;
      }
      if (!this.codigoPostal) {
        this.textoMensajes.push({ mensaje: 'Código postal' });
        this.validado = 0;
      }
      if (this.menor && !this.dniAdulto && this.tipoUsuario == 'usuario') {
        this.textoMensajes.push({ mensaje: 'Dni Adulto' });
        this.validado = 0;
      }
      if (!this.codigoKit && this.tipoUsuario == 'usuario') {
        this.textoMensajes.push({ mensaje: 'Código kit' });
        this.validado = 0;
      }
      if (this.menor && !this.dniMenor && this.tipoUsuario == 'usuario') {
        this.textoMensajes.push({ mensaje: 'Dni Menor' });
        this.validado = 0;
      }
      if (!this.nombre) {
        this.textoMensajes.push({ mensaje: 'Nombre' });
        this.validado = 0;
      }
      if (!this.apellidos) {
        this.textoMensajes.push({ mensaje: 'Apellidos' });
        this.validado = 0;
      }
      if (!this.email) {
        this.textoMensajes.push({ mensaje: 'Email' });
        this.validado = 0;
      }
      if (!this.password) {
        this.textoMensajes.push({ mensaje: 'Contraseña' });
        this.validado = 0;
      }

      if (this.password != this.password2) {
        this.textoMensajes.push({ mensaje: 'Las contraseñas no son iguales' });
        this.validado = 0;
      }
      if (this.uso != 'true' && this.tipoUsuario == 'usuario') {
        this.textoMensajes.push({ mensaje: 'Aceptar Términos de uso' });
        this.validado = 0;
      }
      if (this.cinformado != 'true' && this.tipoUsuario == 'usuario') {
        this.textoMensajes.push({
          mensaje: 'Aceptar Consentimiento informado'
        });
        this.validado = 0;
      }
      if(this.tipoUsuario == 'profesional' && this.cif == '')
      {
        this.textoMensajes.push({
          mensaje: 'Cif'
        });
        this.validado = 0;
      }
      if(this.tipoUsuario == 'profesional' && this.razonSocial == '')
      {
        this.textoMensajes.push({
          mensaje: 'Razón social'
        });
        this.validado = 0;
      }
      if(this.tipoUsuario == 'profesional' && this.entidadBancaria == '')
      {
        this.textoMensajes.push({
          mensaje: 'Entidad bancaria'
        });
        this.validado = 0;
      }
      if(this.tipoUsuario == 'profesional' && this.iban == '')
      {
        this.textoMensajes.push({
          mensaje: 'Iban'
        });
        this.validado = 0;
      }


    },
    accionCerrarMensajes() {
      this.$bvModal.hide('bv-modal-mensajes');
      if (this.validado == 1) {
        this.validado = 0;
        //this.$router.push('Login');
        window.location.href = 'http://mendelsbrain.com/postregistro';
      }
    },
    getProfesionales() {
      axios
        .get(
          'https://api.mendelbrain.com:55020/api/mendels/perfilusuario/profesional',
        )
        .then(
          response => {
            this.profesionales = response.data;
          },
          error => {
                console.log(error);
          }
        );
    }
  },
  mounted() {
    //this.getProfesionales();
  },
  components: {
    //vSelect
  }
};
</script>

<style scoped>
.mensaje {
  background-color: #000000;
}

label {
  display: block;
  margin-top: 10px;
}
.form-label {
  text-align: left;
}

.form-control {
  border-radius: 10px;
}

.btn-registro {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-mensajes {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-registro:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #ffffff;
  border-color: #8caf36;
  background-color: #8caf36;
}
.texfield-formulario {
  background-color: #ffffff;
  border-radius: 10px;
}
input::placeholder {
  color: #bbbbbb;
  font-size: 1em;
}

.texto {
  color: #8caf36;
  font-size: 1.5em;
}
.texto-mensajes {
  color: #aaaaaa;
  font-size: 1em;
}
.titulo-mensajes {
  color: #777777;
  font-size: 1em;
}
.nav-link {
  color: #aaaaaa;
}
.texto-logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  line-height: 1em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
  font-size: 20px;
}
.image-logo {
  text-align: center;
}

.texto-pie {
  text-align: center;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}
label {
  display: block;
  margin-top: 10px;
}
.btn-login {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-login:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #ffffff;
  border-color: #8caf36;
  background-color: #8caf36;
}
.texfield-formulario {
  background-color: #ffffff;
  border-radius: 10px;
}
input::placeholder {
  color: #bbbbbb;
  font-size: 1em;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  max-width: 900px;
  background-color: #ffffff;
  padding: 20px 30px 20px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.profile-img-card {
  width: 80px;
  height: 80px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.contenedor {
  margin: 0px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  position: relative;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 900px) {
  .contenedor {
    margin: 0px 0px 0px 0px;
    padding: 4px 4px 4px 4px;

    text-align: center;
  }
  .texto-pie {
    font-size: 10px;
    text-align: center;
  }
  .texto-logo {
    font-size: 10px;
  }
  .card {
    padding: 20px 0px 20px 0px;
    margin: 0 auto 25px;
  }
}
</style>
